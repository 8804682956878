<template>
	<div class="container">
		<Card background="block">
			<template #header>
				<HeaderNavbar
					:step="1"
				/>
			</template>
			<div class="ebradi-card-body">
				<HeaderInfo
					:image="require('@ebradi/assets/img/image-2.svg')"
					title="Cursos adquiridos"
					sub-title="Confira os cursos que comprou e seus respectivos status"
				>
					<template #info>
						<h5><a href="#">Sair</a></h5>
					</template>
				</HeaderInfo>
				<form onsubmit="return false" name="form-search-cursos" class="pt-6">
					<fieldset class="cards-cursos">
						<div>
							<b-field
								label="Curso"
							>
								<b-input 
									type="text"
								>
								</b-input>
							</b-field>
						</div>
						<div>
							<b-field>
								<label class="label">
									Status 
									<svg 
										@click="() => this.statusInfo = !this.statusInfo"
										width="16"
										class="ml-2"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M7.1547 4.872V3.408H8.8347V4.872H7.1547ZM7.2507 12V6.132H8.7507V12H7.2507Z" fill="#9B7C57"/>
										<circle cx="8" cy="8" r="7.5" stroke="#9B7C57"/>
									</svg>
								</label>
								<b-select placeholder="Todos os status"  icon="" >
									<option
										v-for="option in status"
										:value="option.id"
										:key="option.id">
										{{ option.status }}
									</option>
								</b-select>
							</b-field>
						</div>
					</fieldset>
				</form>
				<div class="ebradi-cards cards-cursos">
					<div
						v-for="(item, index) in cursos"
						:key="index"
						class="column is-6 mt-4"
					>
						<Card 
							:header="item.name"
							background="blur"
							bar
							size="normal"
							:color="(item.status === 'Compra aprovada') ? 'primary' : 'secondary' "
						>
							<div class="ebradi-card-body-curso">
								Data: {{ new Date(item.data).toLocaleDateString() }}<br>
								Forma de pagamento: {{ item.forma_pagamento }}<br>
								Status: <b 
									:class="{
										'has-text-danger':(item.status === 'Falha no pagamento'),
										'has-text-secondary':(item.status === 'Pagamento pendente'),
										'has-text-primary':(item.status === 'Compra aprovada')
									}"
								>
									{{ item.status }}
								</b>
							</div>
							<div class="is-flex is-justify-content-center is-align-content-center pb-4 pt-4">
								<b-button
									:type="typeBtn(item.status)"
									rounded
									:outlined="item.status !== 'Compra aprovada'"
								>
									{{ labelBtn(item.status) }}
								</b-button>
							</div>
						</Card>
					</div>
				</div>
			</div>
		</Card>
		<ModalInfoStatus v-model="statusInfo" />
	</div>
</template>

<script>
import Card from '@ebradi/componets/Card'
import HeaderInfo from '@ebradi/componets/HeaderInfo'
import HeaderNavbar from '@ebradi/componets/HeaderNavbar'
import ModalInfoStatus from '@ebradi/componets/ModalInfoStatus'

export default {
	name: 'CursosAdquiridosPageEBRADI',
	components: {
		Card,
		HeaderNavbar,
		HeaderInfo,
		ModalInfoStatus
	},
	data() {
		return {
			statusInfo: false,
			status: [
				{ id: 1, status: 'Todos os status' },
				{ id: 2, status: 'Compra aprovada' },
				{ id: 3, status: 'Falha no pagamento' },
				{ id: 4, status: 'Compra não-finalizada' },
				{ id: 5, status: 'Pagamento pendente' }
			],
			cursos: [
				{
					name: 'Pós-graduação Online em Direito Societário Aplicado + Execução Penal',
					status: 'Pagamento pendente',
					data: '2024-09-12',
					forma_pagamento: 'Boleto'
				},
				{
					name: 'Pós-graduação Online em Direito Societário Aplicado + Execução Penal',
					status: 'Compra não-finalizada',
					data: '2024-08-06',
					forma_pagamento: 'Cartão de crédito'
				},
				{
					name: 'Pós-graduação Online em Direito Societário Aplicado + Execução Penal',
					status: 'Falha no pagamento',
					data: '2024-06-25',
					forma_pagamento: 'Cartão de crédito'
				},
				{
					name: 'Pós-graduação Online em Direito Societário Aplicado + Execução Penal',
					status: 'Compra aprovada',
					data: '2024-02-10',
					forma_pagamento: 'Pix'
				}
			]
		}
	},
	methods: {
		labelBtn(status) {
			switch (status) {
				case "Compra aprovada":
					return "Acessar curso"
				case "Compra não-finalizada":
					return "Finalizar compra"
				default:
					return 'Ver detalhes'	
			}
		},
		typeBtn(status) {
			switch (status) {
				case "Compra aprovada":
					return "is-primary"
				default:
					return 'is-secondary'	
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	padding: 50px 0;
	max-width: 1150px!important;
	padding-bottom: 400px;
}

.ebradi-card-body {
	padding: 42px 62px;
	padding-top: 62px;

	.btns {
		text-align: center;
		margin-top: 42px;
	}
}

.ebradi-card-body-curso {
	padding: 0 24px;
	padding-bottom: 34px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.ebradi-cards {
	margin: 42px 0;
}

.has-text-secondary {
	color: var(--secondary-400);
}

.cards-cursos {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	gap: 42px;
	margin-bottom: 32px!important;

	& > div {
		max-width: 460px;
		width: 100%;
	}
}
</style>