<template>
	<div v-if="active" class="modal-info-status">
		<div class="modal-info-status-body">
			<Card background="block">
				<div class="info-statu">
					<h2>TIPOS DE STATUS</h2>
					<ul>
						<li>
							<b class="text-color-muted">Compra não-finalizada:</b> O processo de compra foi iniciado, 
							mas ainda não foi concluído. 
							Finalize a compra ao clicar no botão referente.
						</li>
						<li>
							<b class="text-secondary">Pagamento pendente:</b> O pedido foi realizado, mas o pagamento 
							ainda não foi processado. Aguarde a confirmação ou revise as 
							informações de pagamento.
						</li>
						<li>
							<b class="text-danger">Falha no pagamento:</b> Houve um problema ao tentar processar o 
							pagamento. Verifique os detalhes fornecidos ou tente utilizar 
							outro método.
						</li>
						<li>
							<b class="text-cyan-500">Compra aprovada:</b> A compra foi concluída com sucesso e o pagamento foi confirmado. 
							Acesse o curso através do Portal do Estudante > Ulife.
						</li>
					</ul>
					<div class="mt-6 mb-4 is-flex is-justify-content-center">
						<b-button
							type="is-primary"
							rounded
							@click="() => this.active = false"
						>
							Fechar
						</b-button>
					</div>
				</div>
			</Card>
		</div>
	</div>
</template>

<script>
import Card from '@ebradi/componets/Card'

export default {
	name: 'ModalInfoStatasComponentEBRADI',
	components: {
		Card
	},
	props: {
		value: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			active: false
		}
	},
	mounted() {
		this.active = this.value
	},
	watch: {
		value(value) {
			this.active = value
		},
		active(value) {
			this.$emit('input', value)
		}
	}
}
</script>

<style lang="scss" scoped>
.modal-info-status {
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	opacity: 0;
	animation: opacity forwards 0.4s;
	z-index: 998;
		
	&-body {
		margin: 42px 16px;
		width: 100%;
		max-width: 720px;

		.info-statu {
			padding: 20px 40px;
			color: #BADFE7;
			font-size: 16px;

			ul {
				li {
					list-style: disc;
					margin-bottom: 32px;

					&::marker {
						color: var(--secondary-400);
					}
				}
			}

			h2 {
				font-size: 20px;
				margin-top: 20px;
				margin-bottom: 32px;
			}

		}
	}
}

.text-color-muted {
	color: #B2B2B2;
}

.text-danger {
	color: #C93F58;
}

.text-cyan-500 {
	color: #388BA2
}

.text-secondary {
	color: var(--secondary-400);
}

@keyframes opacity {
	from { 
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>
