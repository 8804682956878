<template>
	<div class="ebradi-header-navbar">
		<svg
			class="arrow-left"
			width="15"
			height="34"
			viewBox="0 0 15 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			@click="voltar"
		>
			<path
				d="M14 1L3.75343 10.9106C0.576397 13.9835 0.493311 19.0505 3.56788 22.2258L14 33"
				stroke="#33C1D5"
				stroke-width="2"
			/>
		</svg>

		<div class="logo">
			<img src="@ebradi/assets/img/logo.svg">
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeaderNavbarComponetEBRADI',
	methods: {
		voltar() {
			window.location.href = '/'
		}
	}
}
</script>

<style lang="scss" scoped>
.ebradi-header-navbar {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 42px;
	padding-left: 62px;
	padding-right: 62px;

	.logo {
		margin: auto;
		padding-right: 15px;
	}

	.arrow-left {
		cursor: pointer;
		
		path {
			stroke: var(--primary);
		}
	}
}
</style>